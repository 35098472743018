@media screen and (min-width: 1000px) {

    .ch-cookie-consent {
        left: unset !important;
        width: 50% !important;
        height: fit-content;
        right: 0;
    }
}
@media screen and (max-width: 999px) {
    .ch-cookie-consent {
        left: unset !important;
        width: fit-content !important;
        height: fit-content;
        right: unset;
        padding: 5% !important;
    }
    .ch-cookie-consent__category{
        padding: 0.5rem 0!important;
    }
    .ch-cookie-consent__intro{
        font-size: 0.5rem!important;
    }
    .ch-cookie-consent__category-title{
        font-size: 0.6rem!important;
    }
    .ch-cookie-consent__category-toggle input + label  {
        font-size: 0.6rem!important;
    }
    .ch-cookie-consent__category-toggle input:checked + label, .ch-cookie-consent__category-toggle input + label:hover {
        font-size: 0.6rem!important;
    }
    .ch-cookie-consent__category-toggle input:checked + label, .ch-cookie-consent__category-toggle input + label:hover {
        padding: 0.2rem 0.5rem!important;
    }
    .ch-cookie-consent__category-toggle input + label  {
        padding: 0.2rem 0.5rem!important;
    }
    #cookie_consent_save{
        width: 50%;
        margin-top: 5% !important;
    }
    .ch-cookie-consent__btn-wrapper{
        margin-bottom: 5% !important;
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    .ch-cookie-consent__form{
        margin-top: 5% !important;
    }

}
.ch-cookie-consent__intro{
    font-size: 0.8rem;
}
.ch-cookie-consent__category-title{
    font-size: 0.9rem;
}
label::after, label::before{
    box-sizing: unset!important;
}
label::after{
    padding-left: unset!important;
}
.ch-cookie-consent--dark-theme{
    background-color: $cytise-primary-bg-color!important;
}
.ch-cookie-consent--dark-theme .ch-cookie-consent__category-toggle input:checked + label, .ch-cookie-consent--dark-theme .ch-cookie-consent__category-toggle input + label:hover {
    background-color: #198754!important;
    border-color: white!important;
    color: white!important;
}
.ch-cookie-consent--dark-theme .ch-cookie-consent__category-toggle input + label  {
    background-color: #dc3545!important;
    border-color: unset!important;
    color: black!important;
}

#cookie_consent_save{
    background: unset!important;
    background-color: #198754!important;
    margin-top: 10%;
}
.ch-cookie-consent{
    padding-bottom: 0!important;
}
