footer{

    background-color: $cytise-primary-bg-color;
    color: #ffffff!important;
    a{
        color: #ffffff!important;
        text-decoration: none;
        transition: all 0.3s ease;
        i.bi-calendar-date::before {
            font-size: 4.5rem;
            color: white;
            cursor: pointer;
        }
    }
    h2{
        text-transform: uppercase;
        font-size: 1.2rem;
        position: relative;
        text-decoration: none;
        letter-spacing: 0.05em;
        font-family: $glacial-indifference!important;
    }
}
footer.footer{
    margin-top: 3rem;
}
