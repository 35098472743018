.cytise.steps-item.active{
    border-color:  $cytise-primary-bg-color;
    .steps-content{
       .steps-title{
           color: $cytise-primary-bg-color;
       }
    }
    .steps-icon{
        color: $cytise-primary-bg-color;
    }
}
.cytise.steps-item.completed{
    .steps-icon{
        color: $cytise-primary-bg-color;
    }
}
