@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-Light', 300, normal, ttf);
@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-LightItalic', 300, italic, ttf);
@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-Regular', 400, normal, ttf);
@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-Italic', 400, italic, ttf);
@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-Medium', 500, normal, ttf);
@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-MediumItalic', 500, italic, ttf);
@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-SemiBold', 600, normal, ttf);
@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-SemiBoldItalic', 600, italic, ttf);
@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-Bold', 700, normal, ttf);
@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-BoldItalic', 700, italic, ttf);
@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-ExtraBold', 900, normal, ttf);
@include font-face(OpenSans, '../../../fonts/OpenSans/OpenSans-ExtraBoldItalic', 900, italic, ttf);