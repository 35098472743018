@each $level, $color in $grays {
	.text-gray-#{$level} {
		color: $color;
	}

	.bg-gray-#{$level} {
		background-color: $color;
	}

	.border-gray-#{$level} {
		border-color: $color;
		--#{$prefix}border-color: #{$color};
	}
}

@each $level, $color in $primaries {
	.text-#{$level} {
		color: $color;
	}

	.bg-#{$level} {
		background-color: $color;
	}
}