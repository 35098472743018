.service-card {

    text-align: center;
    margin-top: 40px;
}
.service-card img {
    width: 100%;
    height: auto;
}
.service-description {
    background-color: $cytise-primary-bg-color; /* Dark green background */
    color: #FFFFFF; /* White text color */
    height: 250px;

    bottom: -20px; /* Adjust this to position the text over the background */
    width: 100%;
    font-family: $moontime, cursive; /* Use custom font */
    font-size: 1.8rem;
    text-transform: uppercase;
}
.card-img-top{
    transform: translateY(10%);
}
.service-card .card-body {
    padding: 0; /* Remove padding from card body */
}


@media screen and (min-width: 768px) {
    div.skin-card{
        height: 650px!important;
    }
}
