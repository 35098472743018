h1{
    font-family: $moontime;
    font-size: 7.5rem!important;
}
h2{
    font-family: $hanken-grotesk;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-weight: lighter!important;
    font-size: 1.8rem !important;
}
h3{
    font-family: $hanken-grotesk;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: lighter!important;
    font-size: 1rem !important;

}
body {
    font-family: $hanken-grotesk, sans-serif !important;
    font-size: 1rem;
    font-weight: lighter!important;
    letter-spacing: 0.1rem;
}
.bg-cytise-primary {
    background-color: $cytise-primary-bg-color!important;
}
.color-cytise-primary {
    color: $cytise-primary-bg-color!important;
}
.border-cytise-primary {
    border-color: $cytise-primary-color!important;
}

.border-cytise-primary-light {
    border-color: $cytise-primary-color-light !important;
}
.font-grotesk{
    font-family: $hanken-grotesk;
    font-size: 1rem;
    font-weight: lighter;
    letter-spacing: 0.2rem;
}
.fa-star.text-primary{
    color: $cytise-primary-color!important;
}

.border-light {
    border-color: #dee2e6!important;
}
.card .card-header {
    background-color: $cytise-primary-bg-color;
    color: white;
}
@media screen and (max-width: 768px) {
    ul.fs-md-6{
        font-size: 1.3rem!important;
    }
    h1{
        font-size: 6rem!important;
    }

}

@media screen and (max-width: 576px) {
    ul.fs-md-6{
        font-size: 1.1rem!important;
    }
    h1{
        font-size: 5rem!important;
    }
    h2{
        font-size: 1.5rem !important;
    }
    h3{
        font-size: 1rem !important;
    }
}

//.alert{
//    width: 100vw;
//    position: relative;
//    left: 50%;
//    right: 50%;
//    margin-left: -50vw;
//    margin-right: -50vw;
//    padding-left: 15px; /* Adjust as needed */
//    padding-right: 15px; /* Adjust as needed */
//    border-radius: 0!important;
//}

.translate-tree-quart-middle-x{
    transform: translateX(-75%);
}

.card .card-footer{
    background-color: $cytise-primary-bg-color;
    color: white;
    .btn-link{
        color: white;
    }
}
.cytise-fs-5{
    font-size: 5rem!important;
}
