@media screen and (min-width: 1000px) {
    .menu-responsive {
        display: none!important; /* Hide responsive menu */
    }
}
@media screen and (max-width: 999px) {
    .menu-desktop {
        display: none!important;  /* Hide desktop menu */
    }
}
.navbar {
    background-color: $cytise-primary-bg-color;
}
#cytise-navbar {
    .nav-item {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .nav-link {
        color: #ffffff;
        text-transform: uppercase;
        font-size: 1.2rem;
        position: relative; // Add this line to contain the ::after pseudo-element
        text-decoration: none;
        letter-spacing: 0.05em; /* Adjust as needed */
        font-family: $glacial-indifference;
    }
    .nav-link:hover {
        background-color: $cytise-primary-bg-color;
    }
    .nav-link::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;  /* Adjust this value to place the underline exactly where you want it */
        width: 100%;
        height: 2px;  /* Adjust this value for the underline thickness */
        background-color: #ffffff;;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s ease-out;
    }
    .nav-link:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
    i::before {
      font-weight: bold!important;
    }

}
.navbar-brand {
    height: 80px;
    padding: 0.5rem;
}
#security-link{
    i {
        font-size: 1.5rem;
        color: white;
        cursor: pointer;
    }
    .bi-telephone::before {
        transform: rotateX(-1)!important;
    }
    .icon-link {
        color: white;
        transition: all 0.3s ease;
    }

    .icon-link:hover {
        background: linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.2));
    }

    .custom-dropdown {
        background-color: $cytise-primary-bg-color;
        border: none;
    }

    .custom-dropdown .dropdown-item {
        color: white;
        transition: all 0.3s ease;
    }

    .custom-dropdown .dropdown-item:hover {
        background: linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.2));
        color: white;
    }

    .dropdown-toggle::after {
        display: none;
    }
}
@media (max-width: 1024px) {
    #cytise-navbar .nav-link {
        font-size: 1rem;
    }
}
@media (max-width: 768px) {
    .dropdown-menu {
        width: 60vw;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        max-height: 20vh;
        overflow-y: auto;
    }
    #cytise-navbar .nav-link {
        width: fit-content;
        font-size: 0.9rem;
        padding: 0.1rem;
    }


}
@media (max-width: 500px) {
    .dropdown-menu {
        width: 90vw;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        max-height: 80vh;
        overflow-y: auto;
    }

    .dropdown-item {
        padding: 15px;
        font-size: 16px;
    }

    /* Cart dropdown */
    #sylius-cart-button .dropdown-menu {
        width: 90vw;
        max-width: none;
    }
    #cytise-navbar .nav-link {
        padding: 0.05rem;
    }
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: $cytise-primary-bg-color;
}
.nav-pills .nav-item {
    margin-right: 1rem;
    border-radius: 0;
}
.nav-link:hover {
    background-color: $cytise-secondary-bg-color;
    box-shadow: 0 0 5px 2px rgba($cytise-primary-bg-color, 0.2),
    inset 0 0 2px 1px rgba($cytise-primary-bg-color, 0.1);
}
.account-menu {
    .nav-item {
        border-radius: 0; /* Ensure no rounding on nav item */
    }
    .nav-item .nav-link {
        border-radius: 0; /* Ensures no rounding on the link */
    }
    .nav-item .nav-link:hover {
        box-shadow:
            0 3px 2px -2px rgba($cytise-primary-bg-color, 0.2),
            inset 0 -1px 1px rgba($cytise-primary-bg-color, 0.1);
        border-radius: 0; /* Also ensures hover state has no rounding */
    }
    .nav-item .nav-link.active {
        background-color: $cytise-secondary-bg-color;
        color: $cytise-primary-bg-color;
        font-weight: 900;
        i {
            color: $cytise-primary-bg-color;
        }
    }

}


span.page-link.active, .active > span.page-link {
    background-color: $cytise-primary-bg-color;
    border-color: $cytise-primary-bg-color;
}
