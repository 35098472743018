button.btn-primary,a.btn-primary{
    background-color: $cytise-primary-bg-color;
    border: none;
    color: white;
    font-family: $glacial-indifference;
    text-transform: uppercase;
    transition: all 0.3s ease;
    letter-spacing: 0.05em; /* Adjust as needed */
    border-radius: 0.375rem;
    &:hover{
        background-color: $cytise-primary-bg-color;
        color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    }
    &:first-child:active,&:not(.checked)+.btn:active,&.checked+button:active,&.checked+button:active{
        background-color: $cytise-primary-bg-color;
        color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    }
}
a.btn-outline-primary,button.btn-outline-primary{
    background-color: $cytise-secondary-color;
    border-color: #dee2e6;
    color: black;
    font-family: $glacial-indifference;
    text-transform: uppercase;
    transition: all 0.3s ease;
    letter-spacing: 0.05em; /* Adjust as needed */
    border-radius: 0.375rem;

    &:hover{
        background-color: $cytise-secondary-color;
        border-color: unset;
        color: #dee2e6;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;

    }
    &:first-child:active,&:not(.checked)+.btn:active,&.checked+button:active,&.checked+button:active{
        background-color: $cytise-secondary-color;
        color: #dee2e6;
        border-color: unset;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
    }

}
button.btn.show,a.btn.show{
    background-color: $cytise-primary-bg-color;
    border-color: $cytise-primary-bg-color;
    color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-menu.show .dropdown-item:hover{
    background-color: $cytise-primary-bg-color;
    color: white;
}
span.bg-primary {
    background-color: $cytise-primary-bg-color!important;
}
span.bg-secondary {
    background-color: $cytise-secondary-bg-color!important;
    color: black!important;
}
span.gift-list-item,span.gift-list-item span span {
    font-size: 0.6rem!important;
}
//btn btn-outline-primary w-100
