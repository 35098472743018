input.form-control,textarea.form-control,input:-webkit-autofill,select:-webkit-autofill,textarea:-webkit-autofill{
    border-radius: $border-radius;
    background-color: $cytise-secondary-bg-color!important;
    &:focus{
        background-color: $cytise-secondary-bg-color!important;
        box-shadow: 0 0 5px 2px rgba($cytise-primary-bg-color, 0.2),
        inset 0 0 2px 1px rgba($cytise-primary-bg-color, 0.1);

    }
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #DCCDC0 inset !important;
    box-shadow: 0 0 0 1000px #DCCDC0 inset !important;
    -webkit-text-fill-color: #000 !important; /* Set text color */
    &:focus{
        background-color: $cytise-secondary-bg-color!important;
        box-shadow: 0 0 5px 2px rgba($cytise-primary-bg-color, 0.2),
        inset 0 0 2px 1px rgba($cytise-primary-bg-color, 0.1);
    }
}

.form-control{
    border-color: #dee2e6!important;
}
div .form-select:focus {
    border-color: #dee2e6;
    background-color: $cytise-secondary-bg-color!important;
    box-shadow: 0 0 5px 2px rgba($cytise-primary-bg-color, 0.2),
    inset 0 0 2px 1px rgba($cytise-primary-bg-color, 0.1);
}
label.list-group-item {
    background-color: $cytise-secondary-bg-color;
    border-color: #dee2e6;
    &:focus, &:hover {
        background-color: $cytise-secondary-bg-color!important;
        box-shadow: 0 0 5px 2px rgba($cytise-primary-bg-color, 0.2),
        inset 0 0 2px 1px rgba($cytise-primary-bg-color, 0.1);
    }
}
input.form-check-input:checked{
    background-color: $cytise-primary-bg-color;
    border-color: $cytise-primary-bg-color;
}
